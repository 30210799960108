@import "variables.css";
@import "zeroing.css";

body {
    background-color: var(--backg-color);
}

/*------------------- HEADER -------------------*/
header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    padding: 0 50px;
    background: rgba(
            var(--ac_r),
            var(--ac_g),
            var(--ac_b),
            0.2);
    margin-bottom: 20px;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.navLink {
    padding: 0 5px;
    margin: 0 5px;
    cursor: pointer;
    color: var(--main-color);
    text-decoration: none;
    transition: 0.2s;
}
.navLink:hover {
    color: var(--accent-color);
}

.user {
    padding: 0 0 0 50px;
}


/*------------------- HEADER -------------------*/

.content-container {
    width: calc(100vw - 40px);
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh
    - var(--header-height)
    - var(--footer-height)
    - 20px);
}


.sign-container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}

input {
    height: 30px;
    width: 100%;
    border-radius: 5px;
    background-color: var(--backg-color);
    border: solid var(--accent-color) 1px;
    margin: 10px 0 30px 0;
}
input:focus {
    outline: solid 1px var(--accent-color);
}

button {
    height: 40px;
    padding: 0 30px;
    border-radius: 5px;
    border: none;
    color: var(--backg-color);
    background-color: var(--accent-color);
    margin: 15px auto 0 auto;
    cursor: pointer;
    transition: 0.5s;
}
button:hover {
    background-color: var(--main-color);
}

.orSign {
    margin: 30px 0 0 0;
    align-self: center;
}

.otherSign {
    padding: 5px;
    margin-top: 10px;
    align-self: center;
    color: var(--accent-color);
    text-decoration-line: underline;
}

.shopItem {
    border-bottom: solid var(--accent-color) 1px;
    padding: 15px 0;
}

.itemCard {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 300px;
    min-width: 200px;
    max-width: 450px;
    border-radius: 10px;
    padding: 15px;
    background: rgba(
            var(--ac_r),
            var(--ac_g),
            var(--ac_b),
            0.1);
    margin: 10px;
}

.address {
    font-weight: bolder;
}
.itemsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.itemName {
    font-size: 20px;
    margin-bottom: 10px;
}

.subway {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--main-color);
}

.brand {
    font-weight: bolder;
    font-size: 16px;
}

.characteristic {
    font-weight: bolder;
    padding-right: 15px;
}

.cost {
    margin-top: 15px;
    font-weight: bolder;
    font-size: 20px;
    color: var(--main-color);
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}


.cartedItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 40px);
    background: rgba(
            var(--ac_r),
            var(--ac_g),
            var(--ac_b),
            0.1);
    padding: 20px;
    border-radius: 10px;
}

.itemInfo {
    flex-basis: 300px;
    flex-shrink: 1;
    flex-grow: 1;
    margin-left: 20px;
    min-width: 250px;
}
/* Блок изменения количества шин в корзине */
.numberChanger {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    border-radius: 5px;
    background-color: var(--backg-color);
    margin-top: 5px;
}

/* Блок изменения количества шин в корзине: +- */
.plusOrMinus {
    display: flex;
    justify-content: center;
    height: 45px;
    width: 10px;
    border-radius: 5px;
    text-align: center;
    padding: 10px 20px;
    margin: 0;
    color: inherit;
    background-color: inherit;
    cursor: pointer;
}
.plusOrMinus:hover {
    background: rgba(
            var(--ac_r),
            var(--ac_g),
            var(--ac_b),
            0.25);
}

/* Блок изменения количества шин в корзине: количество шин */
.itemNumber {
    width: 30px;
    text-align: center;
    padding: 10px 22px;
}


h1 {
    margin-left: 30px;
    font-size: 24px;
}
table {
    font-size: 14px;
    padding-top: 10px;
}
td {
    font-weight: lighter;
    color: var(--text-color);
}
p {
    margin: 0;
    font-family: Mulish, sans-serif;
    color: var(--text-color);
}

/*------------------- FOOTER -------------------*/
footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: var(--footer-height);
    background: var(--accent-color);
    color: white;
}
/*------------------- FOOTER -------------------*/